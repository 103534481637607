import React from 'react'

import LayoutWhite from 'components/LayoutWhite/index'

const NotFoundPage = () => (
  <LayoutWhite>
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist</p>
    <p>The site administrators have been informed</p>
  </LayoutWhite>
)

export default NotFoundPage
